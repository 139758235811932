import React from 'react';

import {
	s7ImagePath,
} from '~/global/global.constants';
import { LazyPicture } from '~/util/Components/LazyPicture';
import { Link } from '~/components/Buttons/Components/Link';
import { TextLink } from '~/components/Buttons/Components/TextLink';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

export const StorageGiveawayModalDefault = (props) => {
	const {
		interactEventHandler,
	} = props;

	return (
		<div>
			<div>
				<div className="">
					<LazyPicture
						smallSrcSet={`${s7ImagePath}/storage4KGiveawayModal_sm?size=414,350&scl=1&cb2, ${s7ImagePath}/storage4KGiveawayModal_sm?size=828,700&scl=1&cb2 2x`}
						mediumSrcSet={`${s7ImagePath}/storage4KGiveawayModal?size=660,400&scl=1&cb, ${s7ImagePath}/storage4KGiveawayModal?size=1320,800&scl=1&cb 2x`}
						fallbackSrc={`${s7ImagePath}/storage4KGiveawayModal?size=660,400&scl=1&cb`} />
				</div>
				<div className="tw-p-4">
					<h2 className="tw-heading-3 tw-mb-3">$4K Storage Giveaway</h2>
					<p className="tw-mb-5">Now through January 12, enter for a chance to win a $4,000 gift card* and conquer clutter with modern storage solutions.</p>
					<Link color={ButtonColor.Gray}
						onClick={interactEventHandler}
						href="https://woobox.com/3vrffj"
						variant={ButtonVariant.Hollow}
						isTransparent={true}
						linkExternal={true}
						target="_blank"
					>
								Enter Now
					</Link>
					<p className="tw-mt-5 md:tw-mb-4 tw-text-2xs tw-text-gray-300">*Void where prohibited. No purchase necessary to enter; a purchase will not increase your chance of winning. Subject to  <TextLink href="https://woobox.com/3vrffj/rules" linkExternal={true} target="_blank"><span className="tw-text-2xs">Official Rules</span></TextLink>.</p>
				</div>
			</div>
		</div>
	);
};
